import axios from 'axios';
import CONFIG from '../config/config.js';
import qs from 'qs';
axios.defaults.withCredentials = true;

export function saveSurvey(data) {
  return post(CONFIG.saveJSON, data);
}
export function saveExportSurvey(data) {
  return post(CONFIG.saveExport, data);
}
export const loadSurvey = (code) => {
  return axios
    .get(`${CONFIG.bucket}/${code}.json?rnd=${Math.random() * 788}`, {
      withCredentials: false,
    })
    .then((res) => {
      return Promise.resolve(res);
    })
    .catch((err) => {
      return Promise.reject(err.response);
    });
};

const post = (endpoint, data, api_endpoint = CONFIG.api_endpoint) => {
  let host = window.location.hostname;
  if (host === 'devlocal.se' || host === 'localhost') {
    host = CONFIG.host;
  }
  host = window.location.protocol + '//' + host + api_endpoint;
  return axios
    .post(host + endpoint, qs.stringify(data, { parseArrays: false }))
    .then((res) => {
      return Promise.resolve(res);
    })
    .catch((err) => {
      return Promise.reject(err.response);
    });
};

// const get = (endpoint, api_endpoint = CONFIG.api_endpoint) => {
//   let myHost = window.location.hostname;
//   if (myHost === 'devlocal.se' || myHost === 'localhost') {
//     myHost = CONFIG.host;
//   }
//   let host = window.location.protocol + '//' + myHost + api_endpoint;
//   //console.log(host + endpoint);
//   return axios
//     .get(host + endpoint)
//     .then((res) => {
//       return Promise.resolve(res);
//     })
//     .catch((err) => {
//       return Promise.reject(err.response);
//     });
// };

export function exists(url) {
  return axios
    .head(url)
    .then((res) => {
      if (res.status === 200) {
        return { status: true, url };
      }
      return { status: false, url };
    })
    .catch((err) => {
      return { status: false, url };
    });
}
