import React, { forwardRef } from 'react';
import '../../styles/global/Input.scss';

const Input = forwardRef((props, ref) => {
  const {
    autoComplete,
    onKeyDown,
    style,
    onChange,
    onClick,
    placeholder,
    step,
    type,
    value,
    locked,
  } = props;
  let extra = {};
  if (style) {
    extra = style;
  }
  return (
    <input
      style={extra}
      key="input"
      ref={ref}
      disabled={locked ? true : false}
      type={type ? type : 'text'}
      step={step ? step : 1}
      className="inputField"
      autoComplete={autoComplete ? autoComplete : 'off'}
      onClick={(e) => {
        if (onClick) {
          onClick(e);
        }
      }}
      onKeyDown={(e) => {
        if (onKeyDown) {
          onKeyDown(e);
        }
      }}
      placeholder={placeholder ? placeholder : ''}
      onChange={(e) => {
        if (onChange) {
          onChange(e);
        }
      }}
      value={value !== undefined ? value : ''}
    />
  );
});
export default Input;
