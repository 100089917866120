import React, { useState, useRef, useCallback, useEffect } from 'react';

import ReactFlow, {
  //addEdge,
  //MiniMap,
  Controls,
  //Background,
  ReactFlowProvider,
  //useNodesState,
  useEdgesState,
  applyNodeChanges,
} from 'react-flow-renderer';
import { filter, cloneDeep } from 'lodash';
import FloatingEdge from './utils/FloatingEdge.js';
import '../../styles/components/FlowChartView.scss';
import Controller from '../../containers/controller/Controller';
import ExportButton from '../../containers/global/ExportButton';

const FlowChartView = (props) => {
  const {
    activeNode,
    saveNode,
    setActiveNode,
    allNodes,
    allEdges,
    updatePosition,
  } = props;
  const reactFlowWrapper = useRef(null);
  const [nodes, setNodes] = useState(allNodes);
  const [edges, setEdges] = useEdgesState(allEdges);

  // const [reactFlowInstance, setReactFlowInstance] = useState(null);

  //const onConnect = (params) => setEdges((eds) => addEdge(params, eds));
  //
  let nextId = useRef(1);
  const getID = () => {
    console.log(nextId);
    nextId.current += 1;
    return `${nextId.current}`;
  };
  const onNodesChange = useCallback(
    (changes) => {
      // if (changes[0].type === 'select' && changes[0]?.selected === false) {
      //   setActiveNode(false);
      // }
      setNodes((nds) => applyNodeChanges(changes, nds));
    },
    [setNodes]
  );

  useEffect(() => {
    setEdges(allEdges);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allEdges]);

  useEffect(() => {
    let max = 0;
    allNodes.forEach((p) => {
      if (parseInt(p.id, 10) > max) {
        max = parseInt(p.id, 10);
      }
    });

    nextId.current = max + 1;

    setNodes((nodes) =>
      allNodes.map((node) => {
        let style = { ...node.style };
        if (node.data.type !== 'end') {
          style = {
            ...node.style,
            background: 'var(--blue)',
          };
        }
        if (activeNode?.id) {
          if (node.id === activeNode.id) {
            style = {
              ...node.style,
              background: 'green',
            };
          }
        }
        return { ...node, style };
      })
    );
  }, [allNodes, activeNode]);

  const onDragStop = (event, node) => {
    event.preventDefault();
    event.stopPropagation();
    updatePosition(node);
  };

  const addNode = useCallback(
    (node, label = '', showLabel = false, addLine = false) => {
      let id = getID();
      console.log(id);
      const newNode = {
        id,
        type: 'default',
        data: { type: 'default', label: 'Ny fråga', desc: '' },
        style: {
          border: '1px solid white',
          color: 'white',
          padding: 10,
          background: 'var(--blue)',
        },
        position: {
          x: parseInt(node.position.x, 10),
          y: parseInt(node.position.y, 10) + 50,
        },
      };

      setNodes((nds) => [...nds, newNode]);
      saveNode(newNode);
      return newNode.id;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );
  const addCloneNode = useCallback(
    (node, label = '', showLabel = false, addLine = false) => {
      let data = cloneDeep(node.data);

      const newNode = {
        id: getID(),
        type: 'default',
        data: { ...data, label: node.data.label },
        style: {
          border: '1px solid white',
          color: 'white',
          padding: 10,
          background: 'var(--blue)',
        },
        position: {
          x: parseInt(node.position.x, 10),
          y: parseInt(node.position.y, 10) + 50,
        },
      };

      setNodes((nds) => [...nds, newNode]);
      saveNode(newNode);
      return newNode.id;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );
  // const createEdge = useCallback(
  //   (source, target, label = '', showLabel = false) => {
  //     let edge = {
  //       id: `e${source.id}.${target.id}`,
  //       source: source.id,
  //       target: target.id,
  //       type: 'custom',
  //       label: showLabel && label !== '' ? label : '',
  //       animated: true,
  //       markerEnd: {
  //         type: 'arrowclosed',
  //       },
  //     };

  //     saveEdge(edge);
  //     //setEdges((eds) => [...eds, edge]);
  //   },
  //   []
  // );

  const onNodeClick = (event, node) => {
    if (node.data.type !== 'end') {
      setActiveNode(node.id);
    }
  };

  return (
    <>
      <ReactFlowProvider>
        <div className="flowHolder" ref={reactFlowWrapper}>
          <ReactFlow
            nodes={nodes}
            edges={edges}
            onNodesChange={onNodesChange}
            onNodeDrag={onDragStop}
            //onEdgesChange={onEdgesChange}
            onNodeDoubleClick={onNodeClick}
            //onConnect={onConnect}
            //onInit={setReactFlowInstance}
            edgeTypes={{ floating: FloatingEdge }}
            fitView
            attributionPosition="top-right"
          >
            <Controls />
          </ReactFlow>
        </div>
      </ReactFlowProvider>
      <Controller addNode={addNode} addNodeClone={addCloneNode} />
      <div
        className="addNew"
        onClick={() => {
          let ns = filter(nodes, (n) => n.data.type !== 'end');
          console.log('addNode', nodes, ns[ns.length - 1]);
          addNode(ns[ns.length - 1]);
        }}
      >
        <i className="fa fa-plus"></i>
      </div>
      <ExportButton />
    </>
  );
};

export default FlowChartView;
