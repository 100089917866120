import React, { useState, useEffect } from 'react';
import { findIndex, filter, maxBy, orderBy } from 'lodash';
import '../../styles/components/ControllView.scss';
import Input from '../global/Input';
import Button from '../global/Button';
import Select from '../global/Select';
import Element from './modules/Element';
import QuestionRow from './modules/QuestionRow';
import SelectType from './modules/SelectType';
import ButtonRow from './modules/ButtonRow';
import SlideRow from './modules/SlideRow';
import InputRow from './modules/InputRow';

const ControllView = (props) => {
  const {
    activeNode,
    updateNode,
    nodeTypes,
    setNodeType,
    close,
    nodes,
    addButton,
    removeButton,
    addQuestion,
    removeEdge,
    removeNode,
    addNode,
    addNodeClone,
    saveEdge,
  } = props;
  const [node, setNode] = useState(activeNode);
  const [selected, setSelected] = useState(false);
  const [dirty, setDirty] = useState(false);

  useEffect(() => {
    if (activeNode) {
      setDirty(false);
      setNode(false);
      console.log('update');
      setNode(activeNode);
    } else if (!activeNode) {
      setNode(false);
    }
  }, [activeNode]);

  const saveNodeType = (type) => {
    setNodeType(type, node.id);
  };

  const alterNode = (arr = 'elements', index, newData, orgIndex = -1) => {
    if (orgIndex <= -1) {
      setNode((node) => ({
        ...node,
        data: {
          ...node.data,
          [arr]: node.data[arr].map((el, ind) =>
            ind === index
              ? {
                  ...el,
                  ...newData,
                }
              : el
          ),
        },
      }));
    } else {
      setNode((node) => ({
        ...node,
        data: {
          ...node.data,
          questions: node.data.questions.map((question, ind) =>
            ind === orgIndex
              ? {
                  ...question,
                  [arr]: question[arr].map((el, i) =>
                    i === index
                      ? {
                          ...el,
                          ...newData,
                        }
                      : el
                  ),
                }
              : question
          ),
        },
      }));
    }
  };

  const addEdge = (source, target, label = '', showLabel = false) => {
    let edge = {
      id: `e${source.id}.${target.id}`,
      source: source.id,
      target: target.id,
      type: 'custom',
      label: showLabel && label !== '' ? label : '',
      animated: true,
      markerEnd: {
        type: 'arrowclosed',
      },
    };
    saveEdge(edge);
  };
  useEffect(() => {
    if (node && node.id === activeNode.id) {
      if (
        node.data.type !== 'start' &&
        node.data.type !== 'default' &&
        node.data.type !== 'end'
      ) {
        let ind = findIndex(nodeTypes, (n) => n.type === node.data.type);
        if (ind > -1) {
          setSelected(nodeTypes[ind]);
        }
      }

      let a = { ...activeNode };
      delete a.position;
      let n = { ...node };
      delete n.position;
      if (JSON.stringify(a) !== JSON.stringify(n)) {
        setDirty(true);
      } else {
        setDirty(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [node, nodes]);

  let Row = ButtonRow;
  let btns = false;
  let questions = false;
  if (activeNode && node) {
    if (node.data?.btns) {
      if (node.data.type === 'dropdown') {
        Row = InputRow;
      } else if (node.data.type === 'slide') {
        Row = SlideRow;
      }
      btns = orderBy(node.data?.btns, ['id'], ['desc']);
    }
    if (node.data?.questions) {
      questions = orderBy(node.data.questions, ['id'], ['desc']);
    }
  }
  return (
    <div className={activeNode ? 'controller open' : 'controller'}>
      {activeNode && node && (
        <>
          <div
            className="close"
            onClick={() => {
              close();
            }}
          >
            <i className="fa fa-times" />
          </div>

          {node?.data && <h3 className="desc">{node.data.desc}</h3>}

          {node.data.type === 'default' && (
            <div className="elmHolder" key={`input` + node.id}>
              <SelectType nodeTypes={nodeTypes} setType={saveNodeType} />
            </div>
          )}
          {node.data.type !== 'default' && (
            <div className="elmHolder" key={`name` + node.id}>
              {node.data.type === 'start' && (
                <p className="label">Namn på undersökningen:</p>
              )}
              {node.data.type !== 'start' && <p className="label">Nodnamn:</p>}
              <Input
                value={node.data.label}
                onChange={(e) => {
                  setNode((node) => ({
                    ...node,
                    data: { ...node.data, label: e.target.value },
                  }));
                }}
              />
            </div>
          )}

          {node.data?.elements &&
            node.data.elements.map((item, index) => {
              return (
                <Element
                  key={`element${index}`}
                  {...props}
                  item={item}
                  nodes={nodes}
                  onChange={(e) => {
                    if (e?.target?.value !== undefined) {
                      alterNode('elements', index, { value: e.target.value });
                    } else if (e?.value) {
                      alterNode('elements', index, { value: e.value });
                    }
                  }}
                  onTargetChange={(t) => {
                    removeEdge({ id: `e${node.id}.${item.target}` });
                    alterNode('elements', index, {
                      target: t.id.toString(),
                      lineID: `e${node.id}.${t.id}`,
                    });
                    addEdge(node, { id: t.id.toString() });
                  }}
                  onChangeCheckbox={(isChecked) => {
                    console.log(isChecked);
                    alterNode('elements', index, {
                      value: isChecked,
                    });
                  }}
                />
              );
            })}

          <br />

          {(node.data.type === 'buttons' ||
            node.data.type === 'multi' ||
            node.data.type === 'dropdown' ||
            node.data.type === 'slide' ||
            node.data.type === 'quiz') && (
            <div className="elmHolder" key={`input` + node.id}>
              <p className="label">
                {node.data.type === 'dropdown' && 'Värden:'}
                {(node.data.type === 'multi' || node.data.type === 'buttons') &&
                  'Knappar:'}

                {node.data.type === 'slide' && 'Slides:'}
                {node.data.type === 'quiz' && 'Lägg till fråga:'}
              </p>
              <div
                className="addNew"
                onClick={() => {
                  if (node.data.type === 'quiz') {
                    addQuestion(node);
                  } else {
                    addButton(node);
                  }
                }}
              >
                <i className="fa fa-plus"></i>
              </div>
            </div>
          )}

          {node.data?.questions &&
            questions &&
            questions.map((item, index) => {
              return (
                <div key={`qrow` + index} className="qholder">
                  <QuestionRow
                    item={item}
                    myIndex={findIndex(
                      node.data.questions,
                      (q) => q.id === item.id
                    )}
                    node={node}
                    alterNode={alterNode}
                    addButton={(node) => {
                      addButton(node);
                      let inde = findIndex(
                        node.data.questions,
                        (q) => q.id === item.id
                      );
                      if (!node.data.questions[inde]?.btns) {
                        node.data.questions[inde]['btns'] = [];
                      }

                      let id = maxBy(node.data.questions[inde].btns, 'id');

                      if (!id) {
                        id = 0;
                      } else {
                        id = parseInt(id.id, 10);
                      }

                      setNode((node) => ({
                        ...node,
                        data: {
                          ...node.data,
                          questions: node.data.questions.map((question, ind) =>
                            ind === inde
                              ? {
                                  ...question,
                                  btns: [
                                    ...question.btns,
                                    {
                                      id: id,
                                      type: 'text',
                                      label: 'Text på knappen',
                                      btnText: '',
                                      value: '',
                                      isCorrect: false,
                                      name: '',
                                      showLineLabel: false,
                                      lineID: '',
                                      target: '',
                                    },
                                  ],
                                }
                              : question
                          ),
                        },
                      }));
                    }}
                    updateQuestion={(q, qindex) => {}}
                    {...props}
                  />
                </div>
              );
            })}

          {node.data?.btns &&
            btns &&
            btns.map((item, index) => {
              return (
                <div key={`btnrow` + index} className="btnholder">
                  <Row
                    item={item}
                    qtype={node.data.type}
                    {...props}
                    type={
                      node.data.type === 'buttons' ||
                      node.data.type === 'multi' ||
                      node.data.type === 'dropdown' ||
                      node.data.type === 'slide'
                        ? 'dynamic'
                        : 'start'
                    }
                    delBtn={() => {
                      let ind = findIndex(
                        node.data.btns,
                        (b) => b.id === item.id
                      );
                      removeButton(node, ind);
                      if (item.target) {
                        removeEdge({ id: `e${node.id}.${item.target}` });
                      }
                    }}
                    onClickAdd={() => {
                      let ind = findIndex(
                        node.data.btns,
                        (b) => b.id === item.id
                      );
                      let id = addNode(
                        node,
                        !dirty ? node.data.btns[ind].btnText : '',
                        !dirty ? node.data.btns[ind].showLineLabel : false,
                        true
                      );
                      if (id) {
                        removeEdge({ id: `e${node.id}.${item.target}` });
                        alterNode('btns', ind, {
                          target: id.toString(),
                          lineID: `e${node.id}.${id}`,
                        });

                        addEdge(
                          node,
                          { id: id.toString() },
                          item.btnText,
                          item.showLineLabel
                        );
                      }
                    }}
                    onSelectChange={(t) => {
                      let ind = findIndex(
                        node.data.btns,
                        (b) => b.id === item.id
                      );
                      removeEdge({ id: `e${node.id}.${item.target}` });
                      alterNode('btns', ind, {
                        target: t.id.toString(),
                        lineID: `e${node.id}.${t.id}`,
                      });
                      addEdge(
                        node,
                        { id: t.id.toString() },
                        item.btnText,
                        item.showLineLabel
                      );
                    }}
                    onChangeInput={(e, obj) => {
                      let ind = findIndex(
                        node.data.btns,
                        (b) => b.id === item.id
                      );
                      alterNode('btns', ind, obj);
                    }}
                    onChangeCheckbox={(isChecked) => {
                      let ind = findIndex(
                        node.data.btns,
                        (b) => b.id === item.id
                      );
                      alterNode('btns', ind, {
                        showLineLabel: isChecked,
                      });
                    }}
                  />
                </div>
              );
            })}

          {dirty && (
            <Button
              style={{ marginTop: 20, marginBottom: 20 }}
              onClick={() => {
                updateNode(node);
              }}
            >
              Spara
              <img
                style={{ marginLeft: 20 }}
                alt="spara"
                src={require('../../assets/send.svg').default}
              />
            </Button>
          )}
          {node.data.type !== 'default' &&
            node.data.type !== 'start' &&
            node.data.type !== 'end' && (
              <div className="row">
                <div className="clone">
                  <p className="label">Kopiera nod:</p>
                  <Button
                    onClick={() => {
                      addNodeClone(node);
                    }}
                  >
                    <i className="fa fa-copy" />
                  </Button>
                </div>
                <p className="label">Ändra nodtyp:</p>
                <Select
                  compare={'type'}
                  options={filter(nodeTypes, (t) => t.available === true)}
                  selected={selected}
                  onChange={(t) => {
                    saveNodeType(t.type);
                  }}
                />
              </div>
            )}
          {activeNode && (
            <Button
              className="removeNode"
              onClick={() => {
                removeNode(node);
              }}
            >
              Tabort
              <i className="fa fa-times" />
            </Button>
          )}
        </>
      )}
    </div>
  );
};

export default ControllView;
