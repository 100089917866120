import React, { forwardRef } from 'react';
import '../../styles/global/TextArea.scss';

const TextArea = forwardRef((props, ref) => {
  const {
    autoComplete,
    onKeyDown,
    style,
    onChange,
    onClick,
    placeholder,
    value,
  } = props;
  let extra = {};
  if (style) {
    extra = style;
  }
  return (
    <textarea
      style={extra}
      key="TextArea"
      ref={ref}
      rows={5}
      className="TextAreaField"
      autoComplete={autoComplete ? autoComplete : 'off'}
      onClick={(e) => {
        if (onClick) {
          onClick(e);
        }
      }}
      onKeyDown={(e) => {
        if (onKeyDown) {
          onKeyDown(e);
        }
      }}
      placeholder={placeholder ? placeholder : ''}
      onChange={(e) => {
        if (onChange) {
          onChange(e);
        }
      }}
      value={value ? value : ''}
    ></textarea>
  );
});
export default TextArea;
