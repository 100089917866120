import React from 'react';
import Input from '../../global/Input';
import Button from '../../global/Button';

import SelectQuestion from './SelectQuestion';
import Checkbox from '../../global/Checkbox';

const SlideRow = (props) => {
  const {
    item,
    nodes,
    onClickAdd,
    onChangeCheckbox,
    onSelectChange,
    onChangeInput,
    delBtn,
    type,
  } = props;
  return (
    <div className="btnrow">
      <div>
        <p className="label">Slide text:</p>
        <Input
          name={item.name}
          value={item.btnText}
          locked={item?.locked ? item.locked : false}
          onChange={(e) => {
            if (onChangeInput) {
              onChangeInput(e, {
                btnText: e.target.value,
              });
            }
          }}
        />

        <p className="label">Värde som bilden skall skicka in:</p>
        <Input
          value={item.value}
          onChange={(e) => {
            if (onChangeInput) {
              onChangeInput(e, {
                value: e.target.value,
              });
            }
          }}
        />
        <p className="label">Slide bild url:</p>
        <Input
          name={item.name}
          value={item.src}
          locked={item?.locked ? item.locked : false}
          onChange={(e) => {
            if (onChangeInput) {
              onChangeInput(e, {
                src: e.target.value,
              });
            }
          }}
        />
      </div>
      <>
        <Checkbox
          label="Visa slidetext på linjen"
          checked={item.showLineLabel}
          onChange={(isChecked) => {
            if (onChangeCheckbox) {
              onChangeCheckbox(isChecked);
            }
          }}
        />
      </>
      <div className="target">
        <p className="label">Välj ett existerande mål för sliden:</p>
        <SelectQuestion
          options={nodes}
          selected={item?.target ? item.target : false}
          onChange={(t) => {
            if (onSelectChange) {
              onSelectChange(t);
            }
          }}
        />

        {(item.target === '' || item.target === false) && type !== 'dynamic' && (
          <>
            <p className="label">Skapa mål för slide:</p>
            <Button
              className="small"
              onClick={() => {
                if (onClickAdd) {
                  onClickAdd();
                }
              }}
            >
              Skapa mål
              <i style={{ marginLeft: 10 }} className="fa fa-crosshairs" />
            </Button>
          </>
        )}
        {type === 'dynamic' && (
          <div
            className="remove"
            onClick={() => {
              if (delBtn) {
                delBtn();
              }
            }}
          >
            <i className="fa fa-times" />
          </div>
        )}
      </div>
    </div>
  );
};

export default SlideRow;
