import {
  SET_SURVEY,
  SET_ACTIVE_NODE,
  SET_NODE_TYPE,
  UPDATE_NODE,
  ADD_NODE,
  ADD_BTN,
  DEL_BTN,
  ADD_QUESTION,
  DEL_QUESTION,
  DEL_EDGE,
  DEL_NODE,
  ADD_EDGE,
  CHECK_EDGES,
  UPDATE_POSITION,
} from '../actions/appActions';
import { findIndex, cloneDeep, find, maxBy, filter } from 'lodash';
import { genCode } from '../../utils/utils';

const initialState = {
  nodes: [
    {
      id: '1',
      type: 'input',

      data: {
        type: 'start',
        label: 'Klicka här för att börja',
        desc: 'Första man ser när man når undersökningen en text och en knapp.',
        elements: [
          {
            id: 1,
            type: 'text',
            label: 'Code',
            name: 'code',
            value: genCode(),
            locked: true,
          },
          {
            id: 2,
            type: 'select',
            label: 'Lang',
            name: 'lang',
            value: 'se',
            dataset: 'langs',
          },
          {
            id: 3,
            type: 'text',
            label: 'Cint id',
            name: 'cint_id',
            value: '',
          },
          {
            id: 4,
            type: 'textarea',
            label: 'Intro text',
            name: 'intro_text',
            value: '',
          },
          {
            id: 5,
            type: 'number',
            label: 'Delay innan nästa fråga',
            step: 1000,
            name: 'intro_button_delay',
            value: 0,
          },
        ],
        btns: [
          {
            id: 1,
            type: 'text',
            label: 'Intro knapp',
            name: 'intro_btn',
            showLineLabel: false,
            lineID: '',
            target: '',
            btnText: '',
          },
        ],
      },
      style: {
        border: '1px solid white',
        color: 'white',
        padding: 10,
        background: 'var(--blue)',
      },
      position: { x: 150, y: 0 },
    },
    {
      id: 'end',
      type: 'output',
      data: {
        type: 'end',
        label: 'Slut',
        desc: '',
        elements: [],
        btns: [],
      },
      style: {
        border: '1px solid white',
        color: 'black',
        padding: 10,
        background: 'lightgray',
      },
      position: { x: 205, y: 300 },
    },
  ],
  edges: [],

  activeNode: false,
  actions: [
    { label: '', value: '' },
    { label: 'Spara', value: 'save' },
    { label: 'Analysera', value: 'analyze' },
  ],
  intents: [
    { label: '', value: '' },
    { label: 'Intent', value: 'intent' },
    { label: 'Välj namn', value: 'select_name' },
    { label: 'Chat', value: 'chat' },
  ],
  langs: [
    { value: '', label: '' },
    { value: 'al', label: 'Albanien' },
    { value: 'am', label: 'Armenien' },
    { value: 'au', label: 'Australien' },
    { value: 'az', label: 'Azerbajdzjan' },
    { value: 'be', label: 'Belgien' },
    { value: 'bg', label: 'Bulgarien' },
    { value: 'cy', label: 'Cypern' },
    { value: 'dk', label: 'Danmark' },
    { value: 'ee', label: 'Estland' },
    { value: 'fi', label: 'Finland' },
    { value: 'fr', label: 'Frankrike' },
    { value: 'ge', label: 'Georgien' },
    { value: 'gr', label: 'Grekland' },
    { value: 'ie', label: 'Irland' },
    { value: 'is', label: 'Island' },
    { value: 'il', label: 'Israel' },
    { value: 'it', label: 'Italien' },
    { value: 'hr', label: 'Kroatien' },
    { value: 'lv', label: 'Lettland' },
    { value: 'lt', label: 'Litauen' },
    { value: 'mt', label: 'Malta' },
    { value: 'md', label: 'Moldavien' },
    { value: 'nl', label: 'Nederländerna' },
    { value: 'mk', label: 'Nordmakedonien' },
    { value: 'no', label: 'Norge' },
    { value: 'pl', label: 'Polen' },
    { value: 'pt', label: 'Portugal' },
    { value: 'ro', label: 'Rumänien' },
    { value: 'ru', label: 'Ryssland' },
    { value: 'sm', label: 'San Marino' },
    { value: 'ch', label: 'Schweiz' },
    { value: 'es', label: 'Spanien' },
    { value: 'rs', label: 'Serbien' },
    { value: 'si', label: 'Slovenien' },
    { value: 'uk', label: 'Storbritannien' },
    { value: 'se', label: 'Sverige' },
    { value: 'cz', label: 'Tjeckien' },
    { value: 'de', label: 'Tyskland' },
    { value: 'ua', label: 'Ukraina' },
    { value: 'by', label: 'Vitryssland' },
    { value: 'at', label: 'Österrike' },
    { value: 'lu', label: 'Luxemburg' },
    { value: 'mc', label: 'Monaco' },
    { value: 'tr', label: 'Turkiet' },
    { value: 'yu', label: 'Jugoslavien' },
  ],
  nodeTypes: {
    info: {
      data: {
        type: 'info',
        typeLabel: 'Info',
        label: 'Ny fråga',
        desc: 'Textfeedback utan svar tillbaka',
        elements: [
          {
            id: 1,
            type: 'textarea',
            label: 'Textinformation',
            name: 'text',
            value: '',
          },
          {
            id: 2,
            type: 'text',
            label: 'Cint',
            name: 'cint',
            value: '',
          },
          {
            id: 3,
            type: 'text',
            label: 'Token',
            name: 'token',
            value: '',
          },
          {
            id: 4,
            type: 'text',
            label: 'Status',
            name: 'status',
            value: '',
          },
          {
            id: 5,
            type: 'number',
            label: 'Delay innan nästa fråga',
            step: 1000,
            name: 'delay',
            value: 0,
          },
          {
            id: 8,
            type: 'target',
            target: '',
            lineID: '',
            showLineLabel: false,
          },
        ],
      },
    },
    textfeedback: {
      data: {
        type: 'textfeedback',
        typeLabel: 'Textfråga',
        label: 'Ny fråga',
        desc: 'Fråga med textfält som svar',
        elements: [
          {
            id: 1,
            type: 'textarea',
            label: 'Textinformation',
            name: 'text',
            value: '',
          },
          {
            id: 2,
            type: 'text',
            label: 'Placeholder i textrutan',
            name: 'placeholder',
            value: 'Klicka för att skriva.',
          },
          {
            id: 3,
            type: 'text',
            label: 'Cint',
            name: 'cint',
            value: '',
          },
          {
            id: 4,
            type: 'text',
            label: 'Token',
            name: 'token',
            value: '',
          },
          {
            id: 5,
            type: 'text',
            label: 'Status',
            name: 'status',
            value: '',
          },
          {
            id: 6,
            type: 'number',
            label: 'Delay efter inskickat svar.',
            step: 1000,
            name: 'delay',
            value: 0,
          },
          {
            id: 7,
            type: 'select',
            label: 'Actions',
            name: 'action',
            value: 'save',
            dataset: 'actions',
          },
          {
            id: 8,
            type: 'select',
            label: 'Intents',
            name: 'intent',
            value: '',
            dataset: 'intents',
          },
          {
            id: 9,
            type: 'target',
            lineID: '',
            target: '',
            showLineLabel: false,
          },
          {
            id: 10,
            type: 'action',
            value: '',
            showLineLabel: false,
          },
        ],
      },
    },
    buttons: {
      data: {
        type: 'buttons',
        typeLabel: 'Knappar',
        label: 'Ny fråga',
        desc: 'Fråga med knappar som svar',
        elements: [
          {
            id: 1,
            type: 'textarea',
            label: 'Textinformation',
            name: 'text',
            value: '',
          },
          {
            id: 2,
            type: 'number',
            label: 'Delay efter inskickat svar.',
            step: 1000,
            name: 'delay',
            value: 0,
          },
          {
            id: 3,
            type: 'text',
            label: 'Status',
            name: 'status',
            value: '',
          },
          {
            id: 4,
            type: 'text',
            label: 'Cint',
            name: 'cint',
            value: '',
          },
          {
            id: 5,
            type: 'text',
            label: 'Token',
            name: 'token',
            value: '',
          },
        ],
        btns: [],
      },
    },
    multi: {
      data: {
        type: 'multi',
        typeLabel: 'Multi select',
        label: 'Ny fråga',
        desc: 'Fråga med flerknappsval som svar',
        elements: [
          {
            id: 1,
            type: 'textarea',
            label: 'Textinformation',
            name: 'text',
            value: '',
          },
          {
            id: 2,
            type: 'number',
            label: 'Delay efter inskickat svar.',
            step: 1000,
            name: 'delay',
            value: 0,
          },
          {
            id: 3,
            type: 'text',
            label: 'Status',
            name: 'status',
            value: '',
          },
          {
            id: 4,
            type: 'text',
            label: 'Cint',
            name: 'cint',
            value: '',
          },
          {
            id: 5,
            type: 'text',
            label: 'Token',
            name: 'token',
            value: '',
          },
          {
            id: 6,
            type: 'select',
            label: 'Actions',
            name: 'action',
            value: 'save',
            dataset: 'actions',
          },
          {
            id: 7,
            type: 'target',
            lineID: '',
            target: '',
            showLineLabel: false,
          },
        ],
        btns: [],
      },
    },
    dropdown: {
      data: {
        type: 'dropdown',
        typeLabel: 'Dropdown',
        label: 'Ny fråga',
        desc: 'Fråga med dropdown som svar',
        elements: [
          {
            id: 1,
            type: 'textarea',
            label: 'Textinformation',
            name: 'text',
            value: '',
          },
          {
            id: 2,
            type: 'number',
            label: 'Delay efter inskickat svar.',
            step: 1000,
            name: 'delay',
            value: 0,
          },
          {
            id: 3,
            type: 'text',
            label: 'Cint',
            name: 'cint',
            value: '',
          },
          {
            id: 4,
            type: 'text',
            label: 'Token',
            name: 'token',
            value: '',
          },
          {
            id: 5,
            type: 'text',
            label: 'Status',
            name: 'status',
            value: '',
          },
          {
            id: 6,
            type: 'select',
            label: 'Actions',
            name: 'action',
            value: 'save',
            dataset: 'actions',
          },
          {
            id: 7,
            type: 'select',
            label: 'Intents',
            name: 'intent',
            value: '',
            dataset: 'intents',
          },
        ],
        btns: [],
      },
    },
    video_intro: {
      data: {
        type: 'video_intro',
        typeLabel: 'Video intro',
        label: 'Ny fråga',
        desc: 'Video intro att se',
        elements: [
          {
            id: 1,
            type: 'textarea',
            label: 'Textinformation',
            name: 'text',
            value: '',
          },
          {
            id: 2,
            type: 'number',
            label: 'Delay efter inskickat svar.',
            step: 1000,
            name: 'delay',
            value: 0,
          },
          {
            id: 3,
            type: 'text',
            label: 'Cint',
            name: 'cint',
            value: '',
          },
          {
            id: 4,
            type: 'text',
            label: 'Status',
            name: 'status',
            value: '',
          },
          {
            id: 5,
            type: 'text',
            label: 'Token',
            name: 'token',
            value: '',
          },
          {
            id: 6,
            type: 'text',
            label: 'Video url',
            name: 'video_id',
            value: '',
          },
          {
            id: 7,
            type: 'checkbox',
            label: 'Man måste se klart videon',
            name: 'force_watch',
            value: 0,
          },
          {
            id: 8,
            type: 'select',
            label: 'Actions',
            name: 'action',
            value: 'save',
            dataset: 'actions',
          },
          {
            id: 9,
            type: 'select',
            label: 'Intents',
            name: 'intent',
            value: '',
            dataset: 'intents',
          },
        ],
        btns: [
          {
            id: 1,
            type: 'text',
            label: 'Knapp under videon',
            name: 'btn',
            showLineLabel: false,
            lineID: '',
            target: '',
            btnText: '',
          },
        ],
      },
    },
    quiz: {
      data: {
        type: 'quiz',
        typeLabel: 'Quiz',
        label: 'Ny fråga',
        desc: 'Ett litet quiz som avslutar med att skicka in svaren',
        elements: [
          {
            id: 1,
            type: 'textarea',
            label: 'Textinformation',
            name: 'text',
            value: '',
          },
          {
            id: 2,
            type: 'number',
            label: 'Delay efter inskickat svar.',
            step: 1000,
            name: 'delay',
            value: 0,
          },
          {
            id: 3,
            type: 'text',
            label: 'Cint',
            name: 'cint',
            value: '',
          },
          {
            id: 4,
            type: 'text',
            label: 'Token',
            name: 'token',
            value: '',
          },
          {
            id: 5,
            type: 'text',
            label: 'Status',
            name: 'status',
            value: '',
          },
          {
            id: 6,
            type: 'text',
            label: 'Knapp för att starta quiz',
            name: 'btn',
            value: '',
          },
          {
            id: 7,
            type: 'select',
            label: 'Actions',
            name: 'action',
            value: 'save',
            dataset: 'actions',
          },
          {
            id: 8,
            type: 'select',
            label: 'Intents',
            name: 'intent',
            value: '',
            dataset: 'intents',
          },
          {
            id: 9,
            type: 'target',
            lineID: '',
            target: '',
            showLineLabel: false,
          },
        ],
        questions: [],
      },
    },
    slides: {
      data: {
        type: 'slide',
        typeLabel: 'Slides',
        label: 'Ny fråga',
        desc: 'Slides som avslutar med att skicka vald bild',
        elements: [
          {
            id: 1,
            type: 'textarea',
            label: 'Textinformation',
            name: 'text',
            value: '',
          },
          {
            id: 2,
            type: 'number',
            label: 'Delay efter inskickat svar.',
            step: 1000,
            name: 'delay',
            value: 0,
          },
          {
            id: 3,
            type: 'text',
            label: 'Cint',
            name: 'cint',
            value: '',
          },
          {
            id: 4,
            type: 'text',
            label: 'Token',
            name: 'token',
            value: '',
          },
          {
            id: 5,
            type: 'text',
            label: 'Status',
            name: 'status',
            value: '',
          },
          {
            id: 6,
            type: 'select',
            label: 'Actions',
            name: 'action',
            value: 'save',
            dataset: 'actions',
          },
          {
            id: 7,
            type: 'select',
            label: 'Intents',
            name: 'intent',
            value: '',
            dataset: 'intents',
          },
          {
            id: 8,
            type: 'text',
            label: 'Knapp text',
            name: 'startbtn',
            value: 'Starta',
          },
        ],
        btns: [],
      },
    },
  },
};
const appReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_SURVEY: {
      return {
        ...state,
        nodes: action.nodes,
        edges: action.edges,
        activeNode: false,
      };
    }

    case SET_ACTIVE_NODE: {
      let activeNode = { ...state.activeNode };
      if (action.data !== false) {
        let ind = findIndex(state.nodes, (n) => n.id === action.data);
        if (ind > -1) {
          activeNode = state.nodes[ind];
        } else {
          activeNode = false;
        }
      } else {
        activeNode = false;
      }
      return { ...state, activeNode };
    }
    case UPDATE_POSITION: {
      let nodes = [...state.nodes];
      let ind = findIndex(nodes, (n) => n.id === action.node.id);
      if (ind > -1) {
        nodes[ind].position = action.node.position;
      }

      return { ...state, nodes };
    }
    case ADD_NODE: {
      let nodes = [...state.nodes];
      let ind = findIndex(nodes, (n) => n.id === action.data.id);

      if (ind === -1) {
        nodes.push(action.data);
      }

      return { ...state, nodes };
    }
    case DEL_BTN: {
      let nodes = [...state.nodes];

      let ind = findIndex(nodes, (n) => n.id === action.node.id);
      if (ind >= 0) {
        if (action.pIndex === -1) {
          if (nodes[ind].data?.btns) {
            let data = { ...nodes[ind].data };
            let btns = [...data.btns];

            btns.splice(action.index, 1);

            data.btns = btns;
            nodes[ind].data = data;
          }
        } else {
          if (nodes[ind].data.questions[action.pIndex]?.btns) {
            let question = { ...nodes[ind].data.questions[action.pIndex] };
            let btns = [...question.btns];
            btns.splice(action.index, 1);
            question.btns = btns;
            nodes[ind].data.questions[action.pIndex] = question;
          }
        }
      }

      return {
        ...state,
        nodes,
        activeNode: ind > -1 ? nodes[ind] : state.activeNode,
      };
    }
    case ADD_BTN: {
      let nodes = [...state.nodes];
      let ind = findIndex(nodes, (n) => n.id === action.node.id);
      let btn = {
        id: 0,
        type: 'text',
        label: 'Text på knappen',
        btnText: '',
        value: '',
        name: 'intro_btn',
        showLineLabel: false,
        lineID: '',
        target: '',
      };

      if (ind >= 0) {
        if (action.pIndex === -1) {
          if (!nodes[ind].data?.btns) {
            nodes[ind].data['btns'] = [];
          }
          let id = maxBy(nodes[ind].data.btns, 'id');

          if (!id) {
            id = 0;
          } else {
            id = parseInt(id.id, 10);
          }
          btn.id = id + 1;
          if (nodes[ind].data.type === 'slides') {
            btn['src'] = '';
          }
          nodes[ind].data.btns.push(btn);
        } else {
          if (!nodes[ind].data.questions[action.pIndex]?.btns) {
            nodes[ind].data.questions[action.pIndex]['btns'] = [];
          }
          let id = maxBy(nodes[ind].data.questions[action.pIndex].btns, 'id');

          if (!id) {
            id = 0;
          } else {
            id = parseInt(id.id, 10);
          }
          btn.id = id + 1;
          btn['isCorrect'] = false;
          nodes[ind].data.questions[action.pIndex].btns.push(btn);
        }
      }
      return {
        ...state,
        nodes,
        activeNode: ind > -1 ? nodes[ind] : state.activeNode,
      };
    }

    case DEL_QUESTION: {
      let nodes = [...state.nodes];

      let ind = findIndex(nodes, (n) => n.id === action.node.id);
      if (ind >= 0) {
        if (nodes[ind].data?.questions) {
          let data = { ...nodes[ind].data };
          let questions = [...data.questions];

          questions.splice(action.index, 1);

          data.questions = questions;
          nodes[ind].data = data;
        }
      }

      return {
        ...state,
        nodes,
        activeNode: ind > -1 ? nodes[ind] : state.activeNode,
      };
    }
    case ADD_QUESTION: {
      let nodes = [...state.nodes];
      let ind = findIndex(nodes, (n) => n.id === action.node.id);
      if (ind >= 0) {
        if (!nodes[ind].data?.questions) {
          nodes[ind].data['questions'] = [];
        }
        let id = maxBy(nodes[ind].data.questions, 'id');

        if (!id) {
          id = 0;
        } else {
          id = parseInt(id.id, 10);
        }

        let question = {
          id: id + 1,
          elements: [
            {
              id: 1,
              type: 'textarea',
              label: 'Skriv din fråga',
              name: 'text',
              value: '',
            },
            {
              id: 2,
              type: 'text',
              label: 'Om bild ange url',
              name: 'pic',
              value: '',
            },
          ],
          btns: [],
        };

        nodes[ind].data.questions.push(question);
      }

      return {
        ...state,
        nodes,
        activeNode: ind > -1 ? nodes[ind] : state.activeNode,
      };
    }

    case DEL_NODE: {
      let edges = [...state.edges];

      let nodes = [...state.nodes];

      let ind = findIndex(nodes, (n) => n.id === action.data.id);
      if (ind > -1) {
        nodes.splice(ind, 1);

        let cEdges = [];
        edges.forEach((e, index) => {
          let found = false;
          nodes.forEach((n) => {
            let f = false;
            if (n?.data?.btns) {
              f = find(n.data.btns, { lineID: e.id });
            }
            if (!f && n.data?.elements) {
              f = find(n.data.elements, { lineID: e.id });
            }
            if (f) {
              found = true;
            }
          });
          if (found) {
            cEdges.push(e);
          }
        });
      }
      return { ...state, nodes, edges, activeNode: false };
    }
    case ADD_EDGE: {
      let edges = [...state.edges];
      let ind = findIndex(edges, (e) => e.id === action.data.id);
      if (ind <= -1) {
        edges.push(action.data);
      }

      return { ...state, edges: edges };
    }
    case DEL_EDGE: {
      let edges = [...state.edges];
      let ind = findIndex(edges, (e) => e.id === action.data.id);
      if (ind >= 0) {
        edges.splice(ind, 1);
      }

      return { ...state, edges };
    }
    case CHECK_EDGES: {
      let edges = [...state.edges];
      let nodes = [...state.nodes];
      let cEdges = [];

      edges.forEach((e, index) => {
        let found = false;
        nodes.forEach((n) => {
          let f = false;
          if (n?.data?.btns) {
            f = find(n.data.btns, { lineID: e.id });
          }
          if (!f && n.data?.elements) {
            f = find(n.data.elements, { lineID: e.id });
          }
          if (f) {
            found = true;
          }
        });
        if (found) {
          cEdges.push(e);
        }
      });
      let edge = {
        id: '',
        source: '',
        target: '',
        type: 'custom',
        label: '',
        animated: true,
        markerEnd: {
          type: 'arrowclosed',
        },
      };
      nodes.forEach((n) => {
        //let f = false;
        if (n?.data?.btns) {
          let btns = filter(n.data.btns, (b) => b?.lineID);
          if (btns.length > 0) {
            btns.forEach((b) => {
              let ind = findIndex(cEdges, (e) => e.id === b.lineID);
              if (ind <= -1) {
                cEdges.push({
                  ...edge,
                  id: b.lineID,
                  source: n.id,
                  target: b.target,
                  label: b.showLineLabel && b.btnText !== '' ? b.btnText : '',
                });
              }
            });
          }
          let elms = filter(n.data.elements, (e) => e?.lineID);
          if (elms.length > 0) {
            elms.forEach((el) => {
              let ind = findIndex(cEdges, (e) => e.id === el.lineID);
              if (ind <= -1) {
                cEdges.push({
                  ...edge,
                  id: el.lineID,
                  source: n.id,
                  target: el.target,
                  label:
                    el.showLineLabel && el.btnText !== '' ? el.btnText : '',
                });
              }
            });
          }
        }
      });

      return { ...state, edges: cEdges };
    }

    case SET_NODE_TYPE: {
      let nodes = [...state.nodes];
      let ind = findIndex(nodes, (n) => n.id === action.data.id);
      let n = false;

      if (ind > -1) {
        nodes[ind].data = cloneDeep(state.nodeTypes[action.data.type].data);
        n = nodes[ind];
      }

      return {
        ...state,
        nodes,
        activeNode: ind > -1 ? n : state.activeNode,
      };
    }
    case UPDATE_NODE: {
      let nodes = [...state.nodes];
      let ind = findIndex(nodes, (n) => n.id === action.data.id);
      if (ind > -1) {
        nodes[ind].data = action.data.data;
      }
      let edges = [...state.edges];
      if (edges.length > 0) {
        nodes.forEach((n) => {
          if (n.data?.btns && n.data.btns.length > 0) {
            n.data.btns.forEach((b) => {
              edges = edges.map((e) => {
                if (e.source === n.id && e.target === b.target) {
                  if (b.showLineLabel && b.btnText !== '') {
                    e.label = b.btnText;
                  } else {
                    e.label = '';
                  }
                }
                return e;
              });
            });
          }
          if (n.data?.elements && n.data.elements.length > 0) {
            n.data.elements.forEach((b) => {
              if (b.type === 'target') {
                edges = edges.map((e) => {
                  if (e.source === n.id && e.target === b.target) {
                    if (b.showLineLabel && b.value !== '') {
                      e.label = b.value;
                    } else {
                      e.label = '';
                    }
                  }
                  return e;
                });
              }
            });
          }
        });
      }

      return {
        ...state,
        nodes,
        edges,
        activeNode: action.data,
      };
    }
    default: {
      return state;
    }
  }
};

export default appReducer;
