import React, { useState, useEffect } from 'react';
import Button from './Button';
import { find, findIndex } from 'lodash';
import Tooltip from 'react-simple-tooltip';
import Input from './Input';
import '../../styles/global/ExportButton.scss';

const ExportButtonView = (props) => {
  const { nodes, saveExport, getSurvey, saveData } = props;

  const [show, setShow] = useState(false);
  const [code, setCode] = useState('');
  const [lcode, setLCode] = useState('');
  const [saving, setSaving] = useState(false);
  const [loadingSurvey, setLoadingSurvey] = useState(false);

  useEffect(() => {
    let ind = findIndex(nodes, (n) => n.data.type === 'start');
    if (ind > -1) {
      let i = findIndex(nodes[ind].data.elements, (e) => e.name === 'code');
      if (i > -1) {
        setCode(nodes[ind].data.elements[i].value);
      }
    }
    if (nodes.length > 2) {
      let foundEnd = false;
      nodes.forEach((n) => {
        if (n?.data?.type !== 'start' && n?.data?.type !== 'end') {
          let f = false;
          if (n?.data?.btns) {
            f = find(n.data.btns, { target: 'end' });
          }
          if (!f && n.data?.elements) {
            f = find(n.data.elements, { target: 'end' });
          }
          if (f) {
            foundEnd = true;
          }
        }
      });
      if (foundEnd && !show) {
        setShow(true);
      } else if (!foundEnd && show) {
        setShow(false);
      }
    } else {
      if (show) {
        setShow(false);
      }
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nodes]);

  return (
    <>
      {loadingSurvey && (
        <div className="expBtn">
          <div className="code no">
            <span className="label">Ange Kod:</span>
            <div className="file">
              <Input
                name={'code'}
                value={lcode}
                onChange={(e) => {
                  setLCode(e.target.value);
                }}
              />
              <Button
                onClick={() => {
                  if (lcode.length > 0 && lcode.length < 10) {
                    getSurvey(lcode)
                      .then((res) => {
                        setLCode('');
                        setLoadingSurvey(false);
                      })
                      .catch((err) => {
                        setLCode('');
                        setLoadingSurvey(false);
                      });
                  }
                }}
              >
                <i className="fa fa-download" />
              </Button>
              <Button
                style={{
                  background: 'red',
                  width: 50,
                }}
                onClick={() => {
                  setLCode('');
                  setLoadingSurvey(false);
                }}
              >
                <i className="fa fa-times" />
              </Button>
            </div>
          </div>
        </div>
      )}
      {!loadingSurvey && (
        <div className="expBtn">
          <Tooltip
            content={`Klicka för att läsa in en survey med kod`}
            customCss={{ whiteSpace: 'pre', fontWeight: 700 }}
            padding={10}
            placement={'left'}
          >
            <Button
              onClick={() => {
                if (!saving) {
                  setLoadingSurvey(true);
                }
              }}
              style={
                saving
                  ? { opacity: 0.3, background: 'cadetblue' }
                  : { opacity: 1, background: 'cadetblue' }
              }
            >
              <i className="fa fa-folder-open" />
            </Button>
          </Tooltip>
          <Tooltip
            content={`Spara survey med kod: ${code}\nKoden kopieras automatiskt till urklipp`}
            customCss={{ whiteSpace: 'pre', fontWeight: 700 }}
            padding={10}
            placement={'left'}
          >
            <Button
              onClick={() => {
                if (window.isSecureContext) {
                  navigator.clipboard.writeText(code);
                }
                setSaving(true);
                saveData(code)
                  .then((r) => {
                    setTimeout(() => {
                      setSaving(false);
                    }, 100);
                  })
                  .catch((e) => {
                    setTimeout(() => {
                      setSaving(false);
                    }, 100);
                  });
              }}
              style={
                saving
                  ? { opacity: 0.3, background: 'cadetblue' }
                  : { opacity: 1, background: 'cadetblue' }
              }
            >
              <i className="fa fa-save" />
            </Button>
          </Tooltip>
          <Tooltip
            content={`Spara och Exportera till databasen.\nMinst en fråga måste avslutas vid 'Slut'-noden`}
            customCss={{ whiteSpace: 'pre', fontWeight: 700 }}
            padding={10}
            placement={'left'}
          >
            <Button
              onClick={() => {
                if (show && !saving) {
                  if (window.isSecureContext) {
                    navigator.clipboard.writeText(code);
                  }
                  setSaving(true);
                  saveExport(code)
                    .then((d) => {
                      setTimeout(() => {
                        setSaving(false);
                      }, 100);
                      window.open(`https://chatwithelli.com/${code}`, '_blank');
                    })
                    .catch((err) => {
                      setTimeout(() => {
                        setSaving(false);
                      }, 100);
                    });
                } else {
                  alert('En fråga annan än StartNoden måste gå mot Slutnod');
                }
              }}
              style={
                !show || saving
                  ? { opacity: 0.3, background: 'lightgray' }
                  : { opacity: 1, background: 'cadetblue' }
              }
            >
              <i className="fa fa-file-export" />
            </Button>
          </Tooltip>
          <div className="break"></div>
          <Tooltip
            content={`Klicka för kopiera kod: ${code}`}
            customCss={{ whiteSpace: 'pre', fontWeight: 700 }}
            padding={10}
            placement={'left'}
          >
            <div
              className="code"
              onClick={() => {
                if (window.isSecureContext) {
                  navigator.clipboard.writeText(code);
                }
              }}
            >
              <span className="label">
                Survey kod:
                <i className="fa fa-copy" />
              </span>

              {code}
            </div>
          </Tooltip>
        </div>
      )}
    </>
  );
};

export default ExportButtonView;
