import React, { useState, useRef, useEffect } from 'react';
import { find } from 'lodash';
import '../../../styles/global/Select.scss';

function SelectQuestion(props) {
  const { options: alts, selected: selectedID, onChange } = props;

  const [open, setOpen] = useState(false);

  const [options, setOptions] = useState(alts);
  const [selected, setSelected] = useState(false);

  const [clickedOutside, setClickedOutside] = useState(false);
  const myRef = useRef();

  const handleClickOutside = (e) => {
    if (!myRef.current.contains(e.target)) {
      setClickedOutside(true);
    }
  };
  useEffect(() => {
    if (selectedID) {
      let obj = find(alts, (o) => o.id === selectedID);
      if (obj) {
        setSelected(obj);
      }
    }
    setOptions(alts);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [alts]);

  const handleClickInside = () => setClickedOutside(false);

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);

    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  useEffect(() => {
    if (clickedOutside) {
      setOpen(false);
    }
  }, [clickedOutside]);
  return (
    <div
      className="buttonContainer isSelect"
      ref={myRef}
      onClick={handleClickInside}
    >
      <div className="select">
        <select className="s-hidden">
          {options.length > 0 &&
            options.map((o, ind) => {
              return (
                <option key={`btn_${ind}`} className="selectopt" value={o.id}>
                  {o.id}.{o.data?.label && o.data?.label}
                </option>
              );
            })}
        </select>
        <div
          className={!open ? 'styledSelect' : 'styledSelect active'}
          onClick={() => {
            setOpen(true);
          }}
        >
          {selected &&
            selected.data.type !== 'end' &&
            `${selected.id}.${selected.data.label}`}
          {selected && selected.data.type === 'end' && `Slut på surveyn`}
        </div>
        <ul className="options" style={open ? { display: 'block' } : {}}>
          {options.length > 0 &&
            options.map((o, ind) => {
              return (
                <li
                  key={`btn_ul_${ind}`}
                  className={
                    selected.id === o.id ? 'selected noselect' : 'noselect'
                  }
                  onClick={() => {
                    if (selected.id !== o.id) {
                      setSelected(o);
                      if (onChange) {
                        onChange(o);
                      }
                    }
                    setOpen(false);
                  }}
                  rel={o.id}
                >
                  {o.data.type !== 'end' && `${o.id}. ${o.data.label}`}
                  {o.data.type === 'end' && `Slut på surveyn!`}
                  {selected.id === o.id && <i className="fas fa-check" />}
                </li>
              );
            })}
        </ul>
      </div>
    </div>
  );
}
export default SelectQuestion;
