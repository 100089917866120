import { connect } from 'react-redux';
import {
  updateNode,
  setActiveNode,
  checkEdges,
  addBtn,
  delBtn,
  addQuestion,
  delQuestion,
  delEdge,
  delNode,
  setNodeType,
  addEdge,
} from '../../data/actions/appActions';

import ControllView from '../../components/controller/ControllView';
import { filter, has } from 'lodash';

const mapStateToProps = (state, ownProps) => {
  let nodes = [];
  if (state.app.activeNode) {
    let n = filter(
      state.app.nodes,
      (n) => n.id !== state.app.activeNode.id && n.id !== '1'
    );
    if (n.length > 0) {
      nodes = n;
    }
  }

  let nodeTypes = Object.keys(state.app.nodeTypes).map((nt) => {
    return {
      value: nt,
      desc: state.app.nodeTypes[nt].data.desc,
      type: state.app.nodeTypes[nt].data.type,
      text: state.app.nodeTypes[nt].data.typeLabel,
      label: state.app.nodeTypes[nt].data.typeLabel,
      available: has(state.app.nodeTypes[nt].data, 'available')
        ? state.app.nodeTypes[nt].data.available
        : true,
    };
  });

  return {
    activeNode: state.app.activeNode,
    langs: state.app.langs,
    intents: state.app.intents,
    actions: state.app.actions,
    nodes,
    nodeTypes,
  };
};
const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    updateNode: (node) => {
      return dispatch(updateNode(node));
    },
    removeEdge: (edge) => {
      return dispatch(delEdge(edge));
    },
    saveEdge: (edge) => {
      return dispatch(addEdge(edge));
    },
    removeNode: (node) => {
      return dispatch(delNode(node));
    },
    removeButton: (node, index, pIndex = -1) => {
      return dispatch(delBtn(node, index, pIndex));
    },
    addButton: (node, pIndex = -1) => {
      return dispatch(addBtn(node, pIndex));
    },
    removeQuestion: (node, index) => {
      return dispatch(delQuestion(node, index));
    },
    addQuestion: (node) => {
      return dispatch(addQuestion(node));
    },
    setNodeType: (type, id) => {
      return dispatch(setNodeType(type, id));
    },
    close: () => {
      dispatch(checkEdges());
      return dispatch(setActiveNode(false));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ControllView);
