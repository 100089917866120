import { saveSurvey, saveExportSurvey, loadSurvey } from '../api';
import { findIndex, isObject, isArray, isNumber } from 'lodash';

export const SET_SURVEY = 'setSurvey';
export const SET_ACTIVE_NODE = 'setActiveNode';
export const UPDATE_NODE = 'updateNode';
export const UPDATE_NODE_TARGET = 'updateNodeTarget';
export const UPDATE_POSITION = 'updateNodePosition';
export const SET_NODE_TYPE = 'setNodeType';
export const ADD_NODE = 'addNode';
export const ADD_EDGE = 'addEdge';
export const ADD_BTN = 'addBtn';
export const DEL_BTN = 'delBtn';
export const DEL_EDGE = 'delEdge';
export const DEL_NODE = 'delNode';
export const CHECK_EDGES = 'checkEdges';
export const ADD_QUESTION = 'addQuestion';
export const DEL_QUESTION = 'delQuestion';

export const setSurvey = (nodes, edges) => {
  return {
    type: SET_SURVEY,
    nodes,
    edges,
  };
};
export const checkEdges = () => {
  return {
    type: CHECK_EDGES,
  };
};
export const updateNode = (node) => {
  return {
    type: UPDATE_NODE,
    data: node,
  };
};
export const setNodeType = (type, id) => {
  return {
    type: SET_NODE_TYPE,
    data: { type, id },
  };
};
export const addQuestion = (node) => {
  return {
    type: ADD_QUESTION,
    node,
  };
};
export const delQuestion = (node, index) => {
  return {
    type: DEL_QUESTION,
    node,
    index,
  };
};

export const addBtn = (node, pIndex = -1) => {
  return {
    type: ADD_BTN,
    node,
    pIndex,
  };
};
export const delBtn = (node, index, pIndex = -1) => {
  return {
    type: DEL_BTN,
    node,
    index,
    pIndex,
  };
};
export const addNode = (node) => {
  return {
    type: ADD_NODE,
    data: node,
  };
};
export const delNode = (node) => {
  return {
    type: DEL_NODE,
    data: node,
  };
};
export const delEdge = (edge) => {
  return {
    type: DEL_EDGE,
    data: edge,
  };
};
export const addEdge = (edge) => {
  return {
    type: ADD_EDGE,
    data: edge,
  };
};
export const setActiveNode = (nodeId) => {
  return {
    type: SET_ACTIVE_NODE,
    data: nodeId,
  };
};

export const updateNodePosition = (node) => {
  return {
    type: UPDATE_POSITION,
    node,
  };
};

export const saveData = (code) => {
  return async (dispatch, getCurrentState) => {
    const { nodes, edges } = getCurrentState().app;
    let survey = {
      nodes,
      edges,
    };

    let data = {
      code,
      admin_data: survey,
    };
    console.log(data);
    saveSurvey(data)
      .then((d) => {
        return true;
      })
      .catch((err) => {
        return true;
      });
  };
};

const fixBooleans = (value) => {
  if (isArray(value)) {
    return value.map((v) => fixBooleans(v));
  }
  if (isObject(value)) {
    Object.keys(value).map((k) => (value[k] = fixBooleans(value[k])));
    return value;
  }
  if (isNumber(value)) {
    return value;
  }
  if (value === 'false') return false;
  if (value === 'true') return true;
  return value;
};
export const getSurvey = (code) => {
  return async (dispatch, getCurrentState) => {
    loadSurvey(code)
      .then((res) => {
        if (res.status === 200) {
          if (res?.data) {
            let indata = fixBooleans(res.data);
            console.log(indata);
            let eds = [];
            if (indata?.edges) {
              indata.edges.map((e) => eds.push({ ...e, animated: true }));
            }

            let nds = [];
            if (indata.nodes) {
              indata.nodes.forEach((n) => {
                let data = { ...n.data };
                if (data?.elements) {
                  let elm = data.elements.map((e) => {
                    if (e.type === 'number') {
                      return {
                        ...e,
                        step: parseInt(e.step, 10),
                        value: parseInt(e.value, 10),
                      };
                    }
                    return { ...e };
                  });
                  data.elements = elm;
                }
                nds.push({
                  ...n,
                  data: {
                    ...data,
                  },
                  position: {
                    x:
                      n?.position && n?.position?.x
                        ? parseInt(n.position.x, 10)
                        : 100,
                    y:
                      n?.position && n?.position?.y
                        ? parseInt(n.position.y, 10)
                        : 100,
                  },
                });
              });
            }

            dispatch(setSurvey(nds, eds));
            return true;
          }
        }
        return Promise.reject({ error: 'fail' });
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  };
};

export const saveExport = (code) => {
  return async (dispatch, getCurrentState) => {
    const { nodes, edges } = getCurrentState().app;
    let start = {};
    let ns = [];
    let exclude = ['start', 'end', 'default'];

    nodes.forEach((n) => {
      if (exclude.indexOf(n.data.type) === -1) {
        ns.push(n);
      } else if (n?.data?.type === 'start') {
        start = n;
      }
    });

    let data = {
      survey: start.data.label,
    };
    let mapping = {
      intro_btn: 'intro_button',
      intro_button_delay: 'intro_button_delay',
      intro_text: 'intro_text',
      code: 'code',
      lang: 'lang',
      placeholder: 'placeholder',
      text: 'question',
      cint_id: 'cint_id',
    };

    if (start.data.btns) {
      start.data.btns.forEach((b) => {
        if (b.name === 'intro_btn') {
          data['intro_button'] = b.btnText;
          data['start_id'] =
            b.target !== 'end' ? parseInt(b.target, 10) - 2 : b.target;
        }
      });
    }
    if (start.data.elements) {
      start.data.elements.forEach((e) => {
        data[mapping[e.name]] =
          e.type === 'number' ? parseInt(e.value, 10) : e.value;
      });
    }

    if (ns.length > 0) {
      data['questions'] = [];

      ns.forEach((e) => {
        let q = {};
        q['id'] = parseInt(e.id) - 2;
        q['survey'] = code;
        q['type'] = e.data.type === 'textfeedback' ? 'text' : e.data.type;

        let action = {};

        if (e?.data?.elements) {
          let qStrs = [
            { needle: 'text', label: 'question' },
            { needle: 'placeholder', label: 'placeholder' },
            { needle: 'cint', label: 'placeholder' },
          ];
          qStrs.forEach((s) => {
            let ind = findIndex(e.data.elements, (el) => el.name === s.needle);
            if (ind > -1) {
              q[s.label] = e.data.elements[ind].value;
            }
          });
          let targetStr = e.data.type !== 'quiz' ? 'next_q' : 'on_done';

          let actionStrs = [
            { needle: 'btn', label: 'btn', type: 'string' },
            { needle: 'status', label: 'status', type: 'string' },
            { needle: 'token', label: 'token', type: 'string' },
            { needle: 'cint', label: 'cint', type: 'number' },
            { needle: 'video_id', label: 'video_id', type: 'string' },
            { needle: 'delay', label: 'delay', type: 'number' },
            { needle: 'action', label: 'action', type: 'string' },
            { needle: 'intent', label: 'intent', type: 'string' },
            { needle: 'startbtn', label: 'btn', type: 'string' },
            { needle: 'force_watch', label: 'force_watch', type: 'boolean' },
            { needle: 'target', label: targetStr, type: 'next' },
          ];
          actionStrs.forEach((s) => {
            let ind = findIndex(
              e.data.elements,
              (el) => el.name === s.needle || el.type === s.needle
            );
            if (ind > -1) {
              let val =
                s.needle !== 'target'
                  ? e.data.elements[ind].value
                  : e.data.elements[ind].target;
              if (s.type === 'string' && val !== '') {
                action[s.label] = val;
              } else if (s.type === 'number') {
                action[s.label] = parseInt(val, 10);
              } else if (s.type === 'boolean') {
                action[s.label] =
                  val === 'true' || val === true || val === 1 || val === '1'
                    ? 1
                    : 0;
              } else if (s.type === 'next') {
                val = val !== 'end' ? parseInt(val, 10) - 2 : val;
                action[s.label] = val;
              }
            }
          });
        }

        if (e?.data?.btns) {
          let ops = [];
          e.data?.btns.forEach((b) => {
            let btn = {
              text: b.btnText,
              value: b.value,
            };

            if (b?.target) {
              btn['next_q'] =
                b.target !== '' && b.target !== 'end'
                  ? parseInt(b.target, 10) - 2
                  : 'end';
            }

            if (b?.src) {
              btn['src'] = b.src;
            }
            ops.push(btn);
          });
          if (ops.length > 0 && e.data.type !== 'video_intro') {
            if (e.data.type === 'slide') {
              action['slides'] = ops;
            } else if (e.data.type === 'multi') {
              action['multi'] = ops;
            } else {
              action['options'] = ops;
            }
          } else if (ops.length > 0 && e.data.type === 'video_intro') {
            action['btn'] = ops[0].text;
            action['next_q'] = ops[0].next_q;
          }
        }
        if (
          e.data.type === 'quiz' &&
          e.data?.questions &&
          e.data?.questions.length > 0
        ) {
          let questions = [];
          e.data.questions.forEach((ques, index) => {
            let question = {};
            if (ques?.elements && ques?.elements.length > 0) {
              let qStrs = [
                { needle: 'text', label: 'text' },
                { needle: 'pic', label: 'pic' },
              ];
              qStrs.forEach((s) => {
                let ind = findIndex(
                  ques.elements,
                  (el) => el.name === s.needle
                );
                if (ind > -1) {
                  let val = ques.elements[ind].value;
                  if (val !== '') {
                    question[s.label] = val;
                  }
                }
              });
            }
            if (ques?.btns && ques?.btns.length > 0) {
              let options = [];
              ques.btns.forEach((btn) => {
                if (btn.value !== '' && btn.btnText !== '') {
                  options.push({
                    id: btn.id,
                    text: btn.btnText,
                    value: btn.value,
                  });
                  if (
                    btn.isCorrect === 'true' ||
                    btn.isCorrect === true ||
                    btn.isCorrect === '1' ||
                    btn.isCorrect === 1
                  ) {
                    question['correct'] = btn.id;
                  }
                }
              });
              if (options.length > 0) {
                question['options'] = options;
              }
            }
            questions.push(question);
          });
          action['quiz'] = questions;
        }
        q['action'] = JSON.stringify(action);
        data.questions.push(q);
      });
      let survey = {
        nodes,
        edges,
      };
      data['admin_data'] = survey;
    }
    saveExportSurvey(data)
      .then((d) => {
        return true;
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  };
};
