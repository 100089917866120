import React from 'react';

const SelectType = (props) => {
  const { nodeTypes, setType } = props;
  return (
    <div className="typeselect">
      <h2 className="label">Välj typ:</h2>
      {nodeTypes.map((n, i) => {
        return (
          <div
            key={'node' + i}
            className={n.available ? 'node noselect' : 'node disabled noselect'}
            onClick={() => {
              if (n.available) {
                if (setType) {
                  setType(n.value);
                }
              }
            }}
          >
            <h2>{n.text}</h2>
            {n.desc}
          </div>
        );
      })}
    </div>
  );
};

export default SelectType;
