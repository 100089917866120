import React, { Component } from 'react';
import Container from './containers/Container';
import { Provider } from 'react-redux';

import { BrowserRouter as Router } from 'react-router-dom';
import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import rootReducer from './data/reducers';

const store = createStore(rootReducer, applyMiddleware(thunk));
const history = require('history').createMemoryHistory();

export default class App extends Component {
  render() {
    return (
      <Provider store={store} history={history}>
        <Router>
          <Container />
        </Router>
      </Provider>
    );
  }
}
