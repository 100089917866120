import { connect } from 'react-redux';

import {
  setActiveNode,
  addNode,
  updateNodePosition,
} from '../../data/actions/appActions';
import FlowChartView from '../../components/flowchart/FlowChartView';

const mapStateToProps = (state, ownProps) => {
  return {
    activeNode: state.app.activeNode,
    nodeTypes: state.app.nodeTypes,
    allNodes: state.app.nodes,
    allEdges: state.app.edges,
  };
};
const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    setActiveNode: (nodeId) => {
      return dispatch(setActiveNode(nodeId));
    },
    updatePosition: (node) => {
      return dispatch(updateNodePosition(node));
    },
    saveNode: (node) => {
      return dispatch(addNode(node));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FlowChartView);
