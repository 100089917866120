import React, { useState, useEffect } from 'react';

import '../../styles/global/Checkbox.scss';

const Checkbox = (props) => {
  const { checked: selected, label, onChange } = props;
  const [checked, setChecked] = useState(selected ? true : false);

  useEffect(() => {
    setChecked(selected ? true : false);
  }, [selected]);

  return (
    <label className="checkboxContainer">
      {label && <p className="label">{label}</p>}
      <input
        type="checkbox"
        checked={checked}
        onChange={() => {
          if (onChange) {
            onChange(!checked);
          }
        }}
      />
      <span className="checkmark"></span>
    </label>
  );
};

export default Checkbox;
