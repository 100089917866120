import { useRef, useState, useCallback, useEffect } from 'react';
import { useStore, getBezierPath } from 'react-flow-renderer';

import { getEdgeParams, getCenter } from './utils.js';

function FloatingEdge({ id, source, target, markerEnd, style, label }) {
  const [edgeTextBbox, setEdgeTextBbox] = useState({
    x: 0,
    y: 0,
    width: 0,
    height: 0,
  });
  const labelBgPadding = [2, 4];
  const edgeRef = useRef(null);
  const sourceNode = useStore(
    useCallback((store) => store.nodeInternals.get(source), [source])
  );
  const targetNode = useStore(
    useCallback((store) => store.nodeInternals.get(target), [target])
  );

  useEffect(() => {
    if (edgeRef.current) {
      const textBbox = edgeRef.current.getBBox();
      setEdgeTextBbox({
        x: textBbox.x,
        y: textBbox.y,
        width: textBbox.width,
        height: textBbox.height,
      });
    }
  }, [label]);
  if (!sourceNode || !targetNode) {
    return null;
  }

  const { sx, sy, tx, ty, sourcePos, targetPos } = getEdgeParams(
    sourceNode,
    targetNode
  );

  const [_centerX, _centerY] = getCenter(sx, sy, tx, ty); //offsetX, offsetY

  const d = getBezierPath({
    sourceX: sx,
    sourceY: sy,
    sourcePosition: sourcePos,
    targetPosition: targetPos,
    targetX: tx,
    targetY: ty,
  });

  return (
    <g className="react-flow__edge">
      <path
        id={id}
        className="react-flow__edge-path"
        d={d}
        markerEnd={markerEnd}
        style={style}
      />
      {label && (
        <g
          transform={`translate(${_centerX -
            edgeTextBbox.width / 2} ${_centerY - edgeTextBbox.height / 2})`}
          className="react-flow__edge-textwrapper"
        >
          <rect
            width={edgeTextBbox.width + 2 * labelBgPadding[0]}
            x={-labelBgPadding[0]}
            y={-labelBgPadding[1]}
            height={edgeTextBbox.height + 2 * labelBgPadding[1]}
            class="react-flow__edge-textbg"
            rx="0"
            ry="0"
          ></rect>
          <text
            ref={edgeRef}
            y={edgeTextBbox.height / 2}
            dy="0.3em"
            className="react-flow__edge-text"
          >
            {label}
          </text>
        </g>
      )}
    </g>
  );
}

export default FloatingEdge;
