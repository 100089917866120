import { connect } from 'react-redux';
import { saveExport, getSurvey, saveData } from '../../data/actions/appActions';
import ExportButtonView from '../../components/global/ExportButtonView';

const mapStateToProps = (state, ownProps) => {
  return {
    nodes: state.app.nodes,
    edges: state.app.edges,
  };
};
const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    saveExport: (code) => {
      return dispatch(saveExport(code));
    },
    saveData: (data) => {
      return dispatch(saveData(data));
    },
    getSurvey: (code) => {
      return dispatch(getSurvey(code));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ExportButtonView);
