import React, { useEffect, useState } from 'react';
import { findIndex, orderBy } from 'lodash';
import Input from '../../global/Input';

import Element from './Element';
import Checkbox from '../../global/Checkbox';

const QuestionRow = (props) => {
  const {
    item: child,
    alterNode,
    nodes,
    node,
    myIndex,
    addButton,
    removeQuestion,
    removeButton,
  } = props;
  const [isAltered, setIsAltered] = useState([]);

  const reset = (ind) => {
    if (child.btns.length > 1) {
      child.btns.forEach((item, index) => {
        if (ind !== index && item.isCorrect) {
          alterNode(
            'btns',
            index,
            {
              isCorrect: false,
            },
            myIndex
          );
        }
      });
    }
  };
  useEffect(() => {
    if (child?.btns) {
      if (isAltered.length !== child?.btns.length) {
        let a = [];
        let btns = orderBy(child?.btns, ['id'], ['desc']);
        btns.map((i) => a.push(i.value === '' ? false : true));
        setIsAltered(a);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let btns = false;
  if (child?.btns) {
    btns = orderBy(child?.btns, ['id'], ['desc']);
  }
  return (
    <div className="questionrow">
      {child?.elements &&
        child.elements.map((item, index) => {
          return (
            <Element
              key={`element${index}`}
              {...props}
              item={item}
              nodes={nodes}
              onChange={(e) => {
                if (e?.target?.value) {
                  alterNode(
                    'elements',
                    index,
                    { value: e.target.value },
                    myIndex
                  );
                } else if (e?.value) {
                  alterNode('elements', index, { value: e.value }, myIndex);
                }
              }}
            />
          );
        })}
      <div className="elmHolder" key={`input` + myIndex}>
        <p className="label">Knappar:</p>
        <div
          className="addNew"
          onClick={() => {
            addButton(node, myIndex);
          }}
        >
          <i className="fa fa-plus"></i>
        </div>
      </div>
      {child?.btns &&
        btns &&
        btns.map((item, index) => {
          return (
            <div className="btnrow" key={`qbtn${index}_${myIndex}`}>
              <div>
                <p className="label">Knapp text:</p>
                <Input
                  name={item.name}
                  value={item.btnText}
                  locked={item?.locked ? item.locked : false}
                  onChange={(e) => {
                    let ind = findIndex(child.btns, (b) => b.id === item.id);
                    alterNode(
                      'btns',
                      ind,
                      {
                        btnText: e.target.value,
                      },
                      myIndex
                    );
                    if (!isAltered[ind]) {
                      alterNode(
                        'btns',
                        ind,
                        {
                          value: e.target.value,
                        },
                        myIndex
                      );
                    }
                  }}
                />
                <>
                  <Checkbox
                    label={'Rätt svar'}
                    checked={item.isCorrect}
                    onChange={(isChecked) => {
                      let ind = findIndex(child.btns, (b) => b.id === item.id);
                      reset(ind);
                      alterNode(
                        'btns',
                        ind,
                        {
                          isCorrect: isChecked,
                        },
                        myIndex
                      );
                    }}
                  />
                </>
                <p className="label">Värde som knappen skall skicka in:</p>
                <Input
                  value={item.value}
                  onChange={(e) => {
                    let ind = findIndex(child.btns, (b) => b.id === item.id);
                    let altered = [...isAltered];
                    altered[ind] = true;
                    setIsAltered(altered);
                    alterNode(
                      'btns',
                      ind,
                      {
                        value: e.target.value,
                      },
                      myIndex
                    );
                  }}
                />
              </div>
              <div
                className="remove"
                onClick={() => {
                  let ind = findIndex(child.btns, (b) => b.id === item.id);
                  removeButton(node, ind, myIndex);
                }}
              >
                <i className="fa fa-times" />
              </div>
            </div>
          );
        })}
      <div
        className="remove"
        onClick={() => {
          removeQuestion(node, myIndex);
        }}
      >
        <i className="fa fa-times" />
      </div>
    </div>
  );
};

export default QuestionRow;
