import React, { useState } from 'react';
import Input from '../../global/Input';
import Button from '../../global/Button';

import SelectQuestion from './SelectQuestion';
import Checkbox from '../../global/Checkbox';

const ButtonRow = (props) => {
  const {
    item,
    nodes,
    onClickAdd,
    onChangeCheckbox,
    onSelectChange,
    onChangeInput,
    delBtn,
    type,
  } = props;
  const [isAltered, setIsAltered] = useState(item.value === '' ? false : true);
  return (
    <div className="btnrow">
      <div>
        {item.label && <p className="label">{item.label}:</p>}
        {!item.label && <p className="label">Knapp text:</p>}
        <Input
          name={item.name}
          value={item.btnText}
          locked={item?.locked ? item.locked : false}
          onChange={(e) => {
            if (onChangeInput) {
              onChangeInput(e, {
                btnText: e.target.value,
              });
              if (type === 'dynamic' && !isAltered) {
                onChangeInput(e, {
                  value: e.target.value,
                });
              }
            }
          }}
        />
        {type === 'dynamic' && (
          <>
            <p className="label">Värde som knappen skall skicka in:</p>
            <Input
              value={item.value}
              onChange={(e) => {
                if (onChangeInput) {
                  setIsAltered(true);
                  onChangeInput(e, {
                    value: e.target.value,
                  });
                }
              }}
            />
          </>
        )}
      </div>

      {props.qtype !== 'multi' && (
        <>
          <Checkbox
            label="Visa knapptext på linjen"
            checked={item.showLineLabel}
            onChange={(isChecked) => {
              if (onChangeCheckbox) {
                onChangeCheckbox(isChecked);
              }
            }}
          />
        </>
      )}
      {props.qtype !== 'multi' && (
        <div className="target">
          <p className="label">Välj ett existerande mål för knappen:</p>
          <SelectQuestion
            options={nodes}
            selected={item?.target ? item.target : false}
            onChange={(t) => {
              if (onSelectChange) {
                onSelectChange(t);
              }
            }}
          />

          {(item.target === '' || item.target === false) && type !== 'dynamic' && (
            <>
              <p className="label">Skapa mål för knappen:</p>
              <Button
                className="small"
                onClick={() => {
                  if (onClickAdd) {
                    onClickAdd();
                  }
                }}
              >
                Skapa mål
                <i style={{ marginLeft: 10 }} className="fa fa-crosshairs" />
              </Button>
            </>
          )}
          {type === 'dynamic' && (
            <div
              className="remove"
              onClick={() => {
                if (delBtn) {
                  delBtn();
                }
              }}
            >
              <i className="fa fa-times" />
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default ButtonRow;
