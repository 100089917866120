import React from 'react';

import '../../styles/global/Button.scss';

const Button = (props) => {
  let extra = {};
  if (props?.style) {
    extra = props.style;
  }
  return (
    <div
      style={extra}
      onClick={() => {
        if (props?.onClick) {
          props.onClick();
        }
      }}
      className={
        props.className ? 'btn noselect ' + props.className : 'btn noselect'
      }
    >
      {props.children}
    </div>
  );
};

export default Button;
