import React from 'react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import FlowChart from './flowchart/FlowChart';
import { Routes, Route } from 'react-router-dom';
import '../styles/main.scss';

const Container = (props) => {
  return (
    <React.Fragment>
      <div className={'content'}>
        <Routes>
          <Route path="/" element={<FlowChart />} />
        </Routes>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {};
};
const GetContainer = (props) => {
  let navigate = useNavigate();
  return <Container {...props} navigate={navigate} />;
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(GetContainer);
