import React from 'react';
import Input from '../../global/Input';
import TextArea from '../../global/TextArea';
import SelectQuestion from './SelectQuestion';
import Select from '../../global/Select';
import Checkbox from '../../global/Checkbox';

const Element = (props) => {
  const {
    item,
    onChange,
    nodes,
    onTargetChange,
    onChangeCheckbox,
    actions,
    intents,
    langs,
  } = props;
  const dataset = { intents, actions, langs };

  switch (item.type) {
    case 'text':
      return (
        <div className="elmHolder">
          {item.label && <p className="label">{item.label}:</p>}
          <Input
            name={item.name}
            value={item.value}
            locked={item?.locked ? item.locked : false}
            onChange={(e) => {
              if (onChange) {
                onChange(e);
              }
            }}
          />
        </div>
      );
    case 'number':
      return (
        <div className="elmHolder">
          {item.label && <p className="label">{item.label}:</p>}
          <Input
            type="number"
            name={item.name}
            value={item.value}
            locked={item?.locked ? item.locked : false}
            step={item.step ? item.step : 1}
            onChange={(e) => {
              if (onChange) {
                onChange(e);
              }
            }}
          />
        </div>
      );
    case 'textarea':
      return (
        <div className="elmHolder">
          {item.label && <p className="label">{item.label}:</p>}
          <TextArea
            name={item.name}
            value={item.value}
            onChange={(e) => {
              if (onChange) {
                onChange(e);
              }
            }}
          />
        </div>
      );

    case 'checkbox':
      return (
        <div className="elmHolder">
          <Checkbox
            label={item.label}
            name={item.name}
            checked={item.value}
            onChange={(isChecked) => {
              if (onChangeCheckbox) {
                onChangeCheckbox(isChecked);
              }
            }}
          />
        </div>
      );

    case 'select':
      return (
        <div className="elmHolder">
          {item.label && <p className="label">{item.label}:</p>}
          <Select
            options={dataset[item?.dataset ? item.dataset : 'langs']}
            selected={item ? item : { value: 'se', label: 'Sverige' }}
            compare={'value'}
            onChange={(e) => {
              if (onChange) {
                onChange(e);
              }
            }}
          />
        </div>
      );

    case 'target':
      return (
        <div className="elmHolder">
          <div className="target">
            <p className="label">Välj nod att gå till:</p>
            <SelectQuestion
              options={nodes}
              selected={item?.target ? item.target : false}
              onChange={(t) => {
                if (onTargetChange) {
                  onTargetChange(t);
                }
              }}
            />
          </div>
        </div>
      );

    default:
      return null;
  }
};

export default Element;
