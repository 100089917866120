import React, { useState, useRef, useEffect } from 'react';
import { find } from 'lodash';
import '../../styles/global/Select.scss';

function Select(props) {
  const {
    options: alts,
    selected: selectedObj,
    onChange,
    compare = 'id',
  } = props;

  const [open, setOpen] = useState(false);

  const [options, setOptions] = useState(alts);
  const [selected, setSelected] = useState(false);

  const [clickedOutside, setClickedOutside] = useState(false);
  const myRef = useRef();

  const handleClickOutside = (e) => {
    if (!myRef.current.contains(e.target)) {
      setClickedOutside(true);
    }
  };
  useEffect(() => {
    if (selectedObj) {
      let obj = find(alts, (o) => o[compare] === selectedObj[compare]);
      if (obj) {
        setSelected(obj);
      }
    }

    setOptions(alts);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [alts, selectedObj]);

  const handleClickInside = () => setClickedOutside(false);

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);

    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  useEffect(() => {
    if (clickedOutside) {
      setOpen(false);
    }
  }, [clickedOutside]);

  return (
    <div
      className="buttonContainer isSelect"
      ref={myRef}
      onClick={handleClickInside}
    >
      <div className="select">
        <select className="s-hidden">
          {options.length > 0 &&
            options.map((o, ind) => {
              return (
                <option
                  key={`btn_${ind}`}
                  className="selectopt"
                  value={o[compare]}
                >
                  {o.label}
                </option>
              );
            })}
        </select>
        <div
          className={!open ? 'styledSelect' : 'styledSelect active'}
          onClick={() => {
            setOpen(true);
          }}
        >
          {selected && `${selected.label}`}
        </div>
        <ul className="options" style={open ? { display: 'block' } : {}}>
          {options.length > 0 &&
            options.map((o, ind) => {
              return (
                <li
                  key={`btn_ul_${ind}`}
                  className={
                    selected[compare] === o[compare]
                      ? 'selected noselect'
                      : 'noselect'
                  }
                  onClick={() => {
                    if (selected[compare] !== o[compare]) {
                      setSelected(o);
                      if (onChange) {
                        onChange(o);
                      }
                    }
                    setOpen(false);
                  }}
                >
                  {o.label}
                  {selected && selected[compare] === o[compare] && (
                    <i className="fas fa-check" />
                  )}
                </li>
              );
            })}
        </ul>
      </div>
    </div>
  );
}
export default Select;
