export const genCode = () => {
  const len = 7;
  const charSet = 'id';

  const num = '0123456789';
  const alpha = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';
  const chars = '_-~!@#$%^&*()|}{?></';
  const hu = '-_';

  const sets = {
    n: num,
    a: alpha,
    c: chars,
    an: alpha + num,
    id: alpha + num + hu,
    m: alpha + num + chars,
  };

  const str = sets[charSet];

  let res = '';
  let i = len;
  while (i-- > 0) {
    res += str.charAt(Math.floor(Math.random() * str.length));
  }
  return res;
};
